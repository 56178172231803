.root {
  @apply
  transition-all
  duration-500
}

.rightArrow {
  transform: rotateZ(0deg);
  @apply
  transition-transform
  duration-500
}

.rightArrow90 {
  transform: rotateZ(90deg);
}
