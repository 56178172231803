@layer base {
  @font-face {
    font-family: 'Roboto-Medium';
    src: url('../assets/fonts/Roboto-Medium.eot');
    src: local('Rubik Medium'), local('Roboto-Medium'),
    url('../assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto-Medium.woff') format('woff'),
    url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto-Regular';
    src: url('../assets/fonts/Roboto-Regular.eot');
    src: local('Rubik Regular'), local('Roboto-Regular'),
    url('../assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff'),
    url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto-Bold';
    src: url('../assets/fonts/Roboto-Bold.eot');
    src: local('Rubik Bold'), local('Roboto-Bold'),
    url('../assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto-Bold.woff') format('woff'),
    url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
}


