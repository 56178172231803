.root {
  @apply
  w-full laptop:w-36
  h-11 laptop:h-16
  px-3
  flex
  justify-between
  items-center
  border
  border-bright-gray
  rounded-lg;
}

.activeElement {
  @apply
  text-xsLh22 laptop:text-base
  cursor-pointer
  rounded-3xl
  hover:bg-anti-flash-white;
}

.disabledElement {
  @apply
  text-xsLh22 laptop:text-base
  select-none
  pointer-events-none;
}

.input {
  @apply
  h-full
  text-center
  w-12
  bg-transparent
  select-auto
  outline-none
  text-xsLh22 laptop:text-base
  text-dark-charcoal;

  &:disabled {
    @apply
    select-none
    text-bright-gray;
  }
}
