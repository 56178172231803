.root {
  @apply
  inline-block
  px-3 laptop:px-4
  py-1.5 laptop:py-2
  rounded-xl
  text-xsLh22 laptop:text-base
  content-center
  text-iguana-green
  border
  border-iguana-green;
  background-color: rgba(106, 185, 122, 0.1);
}

.disabled {
  @apply
  text-santas-gray
  border-0
  bg-anti-flash-white;
}
