.transparentBox {
  overflow: hidden;
  width: 100%;
}

.itemsBox {
  display: flex;
  flex-shrink: 0;
  height: 100%;
  transition: all ease-in-out 0.4s;
}

.dots {
  display: flex;
  gap: 0.5rem;
  align-self: center;
}

.dot {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: #E6E6EF;
  transition: all ease-in-out 0.4s;
}

.active {
  background-color: #333333;
}
