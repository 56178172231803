.hiddenCheckbox {
  position: absolute;
  opacity: 0;
  left: -9999px;
}

.checkbox {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.125rem solid #C4C4C4;
  border-radius: 0.4rem;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.hiddenCheckbox:checked + .checkbox {
  border: none;
  background-color: #6AB97A;
  background-image: url('../../../assets/images/check.svg');
  background-repeat: no-repeat;
  background-position: center;
}
