.primary {
  @apply
  bg-iguana-green
  rounded-md
  font-light
  transition
  duration-30
  text-white;
  &:hover {
    background-color: #61A86F;
  }
  &:active {
    background-color: #589364;
  }
  &:disabled {
    @apply bg-anti-flash-white text-silver-sand pointer-events-none;
  }
}

.default {
  @apply
  bg-anti-flash-white
  rounded-md
  font-light
  text-davy-grey
  transition
  duration-30;

  &:hover {
    background-color: #E4E4E9;
  }
  &:active {
    background-color: #D8D8DD;
  }
}

.empty {
  @apply
  bg-transparent
  font-light
  text-davy-grey
  transition
  duration-30;

  &:hover {
    @apply hover:text-opacity-70
  }
  &:active {
    color: #D8D8DD;
  }
}

.secondary {
  @apply
  border
  px-4
  py-3
  rounded-lg
  transition
  bg-transparent;

  &:hover {
    @apply
    bg-anti-flash-white
    bg-opacity-50;
  }
  &:active {
    @apply
    bg-anti-flash-white;
  }
  &:disabled {
    @apply bg-anti-flash-white text-silver-sand pointer-events-none;
  }
}

.danger {
  @apply
  bg-light-carmine-pink
  rounded-md
  font-light
  transition
  duration-30
  text-white;

  &:hover {
    background-color: #d66064;
  }

  &:active {
    background-color: #db5a5e;
  }

  &:disabled {
    @apply bg-anti-flash-white text-silver-sand pointer-events-none;
  }
}
