.cart-panel {
  width: 846px;
}

.form {
  height: 100vh;
}

.small-title {
  color: #9B9DAE;
}

.items-table-wrapper {
  border-radius: 0.375rem;
  overflow: hidden;
  /* Clip any scrollbars that appear */
  border: 1px solid #E9E9E9;
  margin-top: 3rem;
}

.plug {
  background-color: #FAFAFA;
}

.items-table {
  width: 100%;
  display: grid;
  border-collapse: collapse;
  overflow: auto;

  & tbody tr:not(:last-child) {
    &>td {
      border-bottom: 1px solid #E9E9E9;
    }
  }

  :last-child {
    &>td {
      background-color: var(--colors-anti-flash-white);
    }
  }

  & thead,
  & tbody,
  & tr {
    display: contents;
  }

  & thead tr th {
    border-bottom: 1px solid #EAEAEA;
  }

  & th {
    font-size: 1rem;
    color: #9B9DAE;
    font-weight: 400;
    text-align: left;
    padding: 1.5rem 2rem;
  }

  & td {
    font-size: 1.125rem;
    font-weight: 600;
    color: #5A5B61;
    text-align: left;
    padding: 1.625rem 2rem;
  }
}
