.prescriptionPreview {
  width: fit-content;
  max-height: 50rem;

  @apply
  rounded-md
  overflow-y-auto
  bg-white;
}

.selectedCheckBox {
  background-color: #6AB97A;
}

.signBox {
  max-width: 29.063rem;
}
