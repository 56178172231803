.input {
  @apply
  placeholder-chinese-silver
  text-dark-charcoal
  bg-white
  w-full
  h-full
  py-4
  rounded-lg
  border
  border-bright-gray
  transition
  duration-30
  outline-none
  font-roboto-regular
  ease-in-out;

  &:hover {
    @apply border-davy-grey;
  }

  &:focus {
    @apply border-iguana-green caret-iguana-green;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    pointer-events: none;
    @apply bg-transparent
    text-opacity-50;
  }
}

.default {
  @apply pl-4
  pr-12
  text-md;
}

.small {
  @apply pr-2 laptop:pr-4
  pl-2.5 laptop:pl-4
  text-base;
}

.code {
  @apply
  text-center
  w-11
  h-11
  laptop:w-16
  laptop:h-16;
}

.textarea {
  @apply
  bg-white-lilac
  resize-none
  p-6;
  width: 32rem;
}

.date {
  max-height: 3.313rem;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &:not(:valid)::-webkit-datetime-edit {
    color: #c4c4c4;
  }

  @apply
  pr-4
  pl-4;
}

.iconLeft {
  @apply pl-12;
}

.ingredientsDosage {
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  max-width: 7.875rem;

  @apply text-center
  w-auto
  text-base
  py-1
  pl-7
  pr-7;
}

.rightRounded {
  @apply pl-5
  rounded-none
  rounded-r-lg;
}
