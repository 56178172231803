table {
  & tr {
    @apply
    grid
  }

  & tbody {
    & tr {
      @apply
      truncate
    }

    & span {
      @apply
      text-davy-grey
      truncate
      text-base;
    }
  }
}

.table-wrapper {
  border-radius: 0.375rem;
  background: #fff;
  /* Clip any scrollbars that appear */
  border: 1px solid #E9E9E9;

  &.prescriptionMedications {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;

    & td {
      padding: 1.25rem 1rem;
    }
  }
}

.verticalSeparator {
  & tbody tr {
    & > td:not(:last-child) {
      position: relative;

      &:after {
        content: '';
        height: 50%;
        width: 1px;

        position: absolute;
        right: 0;
        top: 25%;

        background-color: #E9E9E9; // The color of your border
      }
    }
  }
}

.headerBottomBorder {
  & thead tr th {
    border-bottom: 1px solid #E9E9E9;
  }
}

.commonTable {
  width: 100%;

  & tbody tr:not(:last-child) {
    & > td {
      border-bottom: 1px solid #E9E9E9;
    }
  }

  & thead tr th {
    background-color: #F7F7FA;
  }

  &.prescriptionMedications {
    height: 100%;

    & thead tr th {
      background-color: white;
    }
  }

  & th {
    position: relative;
    font-size: 1rem;
    color: #9B9DAE;
  }

  & td {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 1.625rem 2rem;
    min-width: 6.25rem;
  }

  & th {
    text-align: left;
    padding: 1.5rem 2rem;
    min-width: 6.25rem;
  }

  &.prescriptionPdf {
    & thead tr th {
      background-color: white;
      font-size: 12px;
      line-height: 14px;
    }

    & td {
      padding: 16px;
    }
  }
}

.commonTable.compactTable {
  & th {
    text-align: left;
    padding: 1.5rem 1rem;
    min-width: 6.25rem;
  }
}

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 0.438rem;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 0.125rem solid transparent;
}

.resize-handle:hover {
  border-color: #ccc;
}

.resize-handle.active {
  border-color: #517ea5;
}
